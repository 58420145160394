<template>
  <div class="column-container">
    <el-form :inline="true" class="search-form" :mode="searchData">
      <el-form-item label="统计时间：">
        <el-date-picker
          v-model="searchData.time"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch"
          >搜索</el-button
        >
      </el-form-item>
    </el-form>

    <el-table :data="listData" max-height="570">
      <el-table-column
        type="index"
        :index="indexMethod"
        label="序号"
        width="100"
      >
      </el-table-column>

      <el-table-column
        prop="columnName"
        label="栏目名称"
        min-width="200"
        show-overflow-tooltip
        class-name="td-align-left th-align-left"
      >
      </el-table-column>

      <el-table-column
        prop="columnReleaseNum"
        label="发布量"
        width="200"
      ></el-table-column>
    </el-table>

    <pagination
      v-show="listPage.total > 0"
      :total="listPage.total"
      :page.sync="listPage.page"
      :limit.sync="listPage.pageSize"
      @pagination="getList"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import {
  getColumnListCount,
  getColumnList,
  sortColumn,
  updateColumnStatus,
  getColumnDetail,
  createColumn,
  updateColumn,
} from "@/api/column";
import { parseTime } from "@/utils/tool.js";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      loading: false,
      listData: [],
      listPage: {
        total: 0,
        page: 1,
        pageSize: 10,
      },
      searchData: {},
    };
  },
  created() {
    this.getListCount();
    this.getList();
  },
  methods: {
    indexMethod(index) {
      return this.listPage.pageSize * (this.listPage.page - 1) + index + 1;
    },
    getListCount() {
      const params = {
        statisticsStartTime: this.searchData.statisticsStartTime,
        statisticsEndTime: this.searchData.statisticsEndTime,
      };

      getColumnListCount(params).then((res) => {
        this.listPage.total = res.data.result;
      });
    },
    getList() {
      const params = {
        page: this.listPage.page,
        pageSize: this.listPage.pageSize,
        statisticsStartTime: this.searchData.statisticsStartTime,
        statisticsEndTime: this.searchData.statisticsEndTime,
      };
      this.loading = "list";
      getColumnList(params).then((res) => {
        this.listData = res.data.result;
        this.loading = false;
      });
    },
    handleSearch() {
      this.searchData.statisticsStartTime =
        this.searchData.time && this.searchData.time[0]
          ? this.searchData.time[0]
          : "";
      this.searchData.statisticsEndTime =
        this.searchData.time && this.searchData.time[1]
          ? this.searchData.time[1]
          : "";
      this.getListCount();
      this.getList();
    },
  },
};
</script>
